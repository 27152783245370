$font-size-smallest: 10px;
$font-size-small: 12px;
$font-size-normal: 14px;
$font-size-bigger: 16px;
$font-size-big: 20px;
$font-size-biggest: 24px;

.smallest {
  font-size: $font-size-smallest;
}

.small {
  font-size: $font-size-small;
}

.normal {
  font-size: $font-size-normal;
}

body, button, input {
  @extend .normal;
  font-family: 'Open Sans', sans-serif;
  color: $ulverbite-light-black;
}

h1 {
  font-size: $font-size-biggest;
  color: $ulverbite-black;
  font-weight: 300;
  margin: 24px 0;
}

h2 {
  font-size: $font-size-big;
  color: $ulverbite-black;
  font-weight: 300;
  margin: 24px 0;
}

h3 {
  font-size: $font-size-big;
  color: $ulverbite-dark-gray;
  font-weight: 300;
  margin: 24px 0;
}

h4 {
  font-size: $font-size-bigger;
  color: $ulverbite-black;
  font-weight: 600;
  text-transform: uppercase;
  margin: 10px 0;
}
