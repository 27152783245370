.buttonish.w-secondary {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.buttonish.secondary-button {
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 1px !important;

  > svg {
    margin: 0;
    width: 18px;
    height: 18px;

    > path {
      fill: white;
    }
  }
}

.secondary-menu-holder {
  position: relative;
  display: inline;
}

.secondary-menu {
  position: absolute;
  top: 35px;
  right: 0px;

  &.bottom {
    top: auto;
    bottom: 35px;
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .buttonish.w-secondary {
      width: calc(100% - 42px - 1px);
      vertical-align: middle;
    }

    .buttonish.secondary-button {
      width: 42px;
      vertical-align: middle;
    }
  }
}
