.subscription-info {
  padding: 40px;
  text-align: center;
  border-bottom: 1px solid $ulverbite-dark-gray;

  > .gray-uppercase {
    display: inline-block;

    > .edit-link {
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .subscription-info {
      > img {
        top: 0;
        margin-bottom: 5px;
      }

      > .gray-uppercase {
        display: block;
        
        > .edit-link {
          margin: 5px 0 0 0;
          display: block;
        }
      }
    }
  }
}
