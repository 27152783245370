div.error-view {
  margin-top: 100px;
  text-align: center;

  > div.message {
    margin-top: 60px;
    line-height: 2;
  }

  > div.link {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    div.error-view {
      margin: 20px;

      > img {
        width: 80px;
      }

      > div.message {
        margin-top: 20px;
        line-height: 1.5;
      }
    
      > div.link {
        margin-top: 20px;
      }
    }
  }
}