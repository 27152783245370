.payment-history {
  position: relative;
  gap: 20px;
  
  > .remove-button {
    left: -32px;
    top: 40px;
  }

  > .control-row {
    margin: 0;

    &.small {
      width: 180px;
    }

    &.big {
      width: auto;
      flex-grow: 1;
    }
  }

  &:not(.index0) {
    padding-top: 10px;

    > .control-row > .text {
      display: none;
    }
  
    > .remove-button {
      top: 10px;
    }
  }
}

.payment-progress {
  $progress-radius: 5px;

  > .progress-bar {
    width: 100%;
    height: 5px;
    background: $ulverbite-pastel-gray;
    position: relative;
    border-radius: $progress-radius;
    margin-top: 10px;

    > .fill {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: $ulverbite-green;
      border-radius: $progress-radius;
      transition: width 0.2s;
    }
  }

  > .text {
    margin-top: 5px;

    > span.header {
      margin-right: 4px;
    }

    > span.value {
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .payment-history {
      > .remove-button {
        top: 25px;
        left: auto;
        right: -9px;
      }

      &:not(.index0) {
        border-top: 1px dashed $ulverbite-dark-gray;
        margin-top: 20px;
        padding-top: 0;
        
        > .control-row > .text {
          display: block;
        }

        > .remove-button {
          top: 5px;
        }
      }
    }
  }
}
