.invoice-stats-bar {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 0 -10px;
  padding-bottom: 30px;

  > .box {
    background: white;
    border-radius: 12px;
    padding: 20px 16px;
    flex: 1;
    margin: 0 10px;
    display: flex;

    > svg {
      height: 50px;
    }

    > div.texts {
      margin-left: 10px;
      
      > div.title {
        font-weight: 600;
        color: $ulverbite-black;
        text-transform: uppercase;

        > div.year-changer {
          position: absolute;
          display: inline;
          padding: 5px;
          margin-top: -5px;
          cursor: pointer;
        }
      }

      > button.sum {
        border: none;
        background: none;
        outline: none;
        padding: 0;
        margin: 0;
        margin-top: 5px;
        font-size: 28px;
        color: $ulverbite-black;
        font-weight: 300;
        display: inline;

        &.loading {
          color: $ulverbite-dark-gray;
        }

        &:not(:disabled) {
          cursor: pointer;
        }
      }
    }
  }
}
