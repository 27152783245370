.buttonish.create {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .buttonish.create {
      margin-top: 20px;
      margin-bottom: 20px;

      &.w-secondary {
        padding-right: 0; // Hack on mobile to prevent breaking the create button with long text. This will need to be fixed when we have localization.
        width: calc(100% - 43px);
      }
    }
  }
}
