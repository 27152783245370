$row-header-width: 340px;
$subscription-page-min-width: 960px;

.package-types-tabs {
  padding-left: $row-header-width;
  text-align: center;
  margin-bottom: 40px;
  box-sizing: border-box;
  min-width: $subscription-page-min-width;
}

.pricing-flex {
  min-width: $subscription-page-min-width;
  justify-content: center;

  > .column {
    
    > .box {
      background: white;
      border-radius: 6px;
      text-align: center;
      margin: 0 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      width: 200px;

      &.most-popular {
        width: 240px;
        margin-top: -20px;
        margin-bottom: -20px;

        > .column-header {
          padding-top: 40px;
        }
      }

      > .row:nth-child(even) {
        background: $ulverbite-white;
      }
    }

    .column-header {
      height: 160px;
      padding: 20px;

      > .description {
        line-height: 20px;
        font-size: $font-size-small;
      }
    }

    .column-footer {
      padding: 40px 0;

      > div.selected {
        line-height: 40px;

        > button {
          margin-top: 10px;
        }
      }
    }

    .row {
      height: 30px;
      line-height: 30px;

      > .emoji {
        width: 20px;
      }
    }

    &.row-header {
      font-size: $font-size-small;
      min-width: $row-header-width;
      text-align: right;

      > div.row {
        padding-right: 20px;
      }
    }

    .buttonish {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.pricing-footer {
  margin-left: 300px;
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
  font-size: $font-size-small;
  line-height: 1.5;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .package-types-tabs {
      min-width: 0;
      padding-left: 0;
      margin-top: 20px;
      margin-bottom: 0;
    }

    .pricing-flex {
      display: block;
      min-width: 0;

      > .column.inline-flex {
        display: block;

        > .box {
          width: auto;
          margin: 0;
          margin-bottom: 20px;
        }

        .row {
          display: none;
        }

        .buttonish {
          width: auto;
        }

        .column-header {
          padding: 20px 0;
          height: auto;
        }

        .column-footer {
          padding: 0;
          padding-bottom: 20px;
        }
      }
    }
    
    .pricing-footer {
      margin: 20px 0;

      > div {
        margin-top: 10px;
      }
    }
  }
}
