@import "~react-datepicker/dist/react-datepicker.css";
.error {
  color: #b53310;
}

.smallest {
  font-size: 10px;
}

.small {
  font-size: 12px;
}

.normal, body, button, input {
  font-size: 14px;
}

body, button, input {
  font-family: "Open Sans", sans-serif;
  color: #4a4e55;
}

h1 {
  font-size: 24px;
  color: #242526;
  font-weight: 300;
  margin: 24px 0;
}

h2 {
  font-size: 20px;
  color: #242526;
  font-weight: 300;
  margin: 24px 0;
}

h3 {
  font-size: 20px;
  color: #d0d7e6;
  font-weight: 300;
  margin: 24px 0;
}

h4 {
  font-size: 16px;
  color: #242526;
  font-weight: 600;
  text-transform: uppercase;
  margin: 10px 0;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60px;
  border-right: 1px solid #d0d7e6;
  background: #f0f3f8;
  z-index: 1;
}
nav ol {
  position: fixed;
}
nav ol.top {
  top: 0;
}
nav ol.bottom {
  bottom: 0;
}
nav ol > li {
  display: block;
}
nav ol > li > .nav-item {
  text-decoration: none;
  text-align: center;
  display: block;
  width: 60px;
  color: #5d6a8a;
  cursor: pointer;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  padding-top: 11px;
  padding-bottom: 8px;
  outline: none;
  box-sizing: border-box;
}
nav ol > li > .nav-item > div.icon {
  height: 25px;
}
nav ol > li > .nav-item > div.icon > svg > path {
  fill: #242526;
}
nav ol > li > .nav-item > div.title {
  font-size: 10px;
  margin-top: 2px;
}
nav ol > li > .nav-item.hidden {
  display: none;
}
nav ol > li > .nav-item.logo {
  padding: 36px 0;
}
nav ol > li > .nav-item.logo > svg {
  width: 30px;
  height: 30px;
  fill: #242526;
}
nav ol > li > .nav-item.selected {
  border-left-color: #11c16f;
  color: #242526;
  background: #e2e6f0;
}
nav ol > li > .nav-item.selected > .icon > svg > path {
  fill: #11c16f;
}
nav ol > li > .nav-item:hover:not(.selected):not(.logo) {
  background: #e2e6f0;
  color: #242526;
}

.mobile-header {
  display: none;
  justify-content: space-between;
  border-bottom: 1px solid #d0d7e6;
  background: #f0f3f8;
}
.mobile-header > .menu-element {
  min-width: 30px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.mobile-header > .menu-element > h2 {
  margin: 0;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .mobile-header {
    display: flex;
    align-items: center;
  }
  body:not(.force-desktop) nav {
    width: auto;
    border-right: none;
    right: 0;
    top: 44px;
  }
  body:not(.force-desktop) nav ol {
    position: static;
  }
  body:not(.force-desktop) nav ol > li > .nav-item {
    width: auto;
    display: flex;
    padding: 20px 23px;
    display: flex;
    align-items: center;
  }
  body:not(.force-desktop) nav ol > li > .nav-item > div.icon {
    height: auto;
  }
  body:not(.force-desktop) nav ol > li > .nav-item > div.title {
    font-size: 12px;
    margin-top: -3px;
    margin-left: 20px;
  }
}
.modal-content .MuiDialog-paper {
  background-color: #f0f3f8;
  border-radius: 12px;
  width: 800px;
  max-width: 800px;
  padding: 25px 40px;
  outline: none;
  box-sizing: border-box;
  position: relative;
}
.modal-content .MuiDialog-paper h1.title {
  text-align: center;
  margin: 0 40px 20px 40px;
}
.modal-content .MuiDialog-paper .close-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 6px;
}
.modal-content .MuiDialog-paper .close-button > svg {
  margin-top: 20px;
  margin-left: 20px;
}
.modal-content .MuiDialog-paper .tab-center > ol.tab-control {
  margin-bottom: 20px;
}

.alert-modal-content {
  text-align: center;
  padding: 10px 0;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .modal-content .MuiDialog-paper {
    padding: 20px 10px;
    margin-left: 5px;
    margin-right: 5px;
  }
  body:not(.force-desktop) .alert-modal-content {
    padding: 0px;
  }
}
a, .link {
  color: #46688B;
  text-decoration: none;
  cursor: pointer;
}
a:hover, .link:hover {
  color: #567595;
}

.buttonish {
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: #11c16f;
  padding: 9px 15px;
  padding-right: 25px;
  min-height: 40px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  color: white;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  outline: none;
}
.buttonish.text-only {
  padding-left: 40px;
  padding-right: 40px;
}
.buttonish.secondary {
  background-color: #808ba1;
}
.buttonish.secondary:hover:not(:disabled) {
  background-color: #5d6a8a;
}
.buttonish.white {
  background-color: #f9f9fb;
  border-color: #d0d7e6;
}
.buttonish.white:hover:not(:disabled) {
  background-color: #e2e6f0;
}
.buttonish.square {
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 12px;
}
.buttonish.square > svg {
  margin: 0;
}
.buttonish:disabled {
  background-color: #d0d7e6;
  cursor: default;
}
.buttonish:hover:not(:disabled) {
  background: #0ca56d;
  color: white;
}
.buttonish > svg {
  margin-right: 15px;
  position: relative;
  top: -2px;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .buttonish {
    width: 100%;
  }
  body:not(.force-desktop) .buttonish.square {
    width: auto;
  }
}
.buttonish.create {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .buttonish.create {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  body:not(.force-desktop) .buttonish.create.w-secondary {
    padding-right: 0;
    width: calc(100% - 43px);
  }
}
.buttonish.w-secondary {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.buttonish.secondary-button {
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 1px !important;
}
.buttonish.secondary-button > svg {
  margin: 0;
  width: 18px;
  height: 18px;
}
.buttonish.secondary-button > svg > path {
  fill: white;
}

.secondary-menu-holder {
  position: relative;
  display: inline;
}

.secondary-menu {
  position: absolute;
  top: 35px;
  right: 0px;
}
.secondary-menu.bottom {
  top: auto;
  bottom: 35px;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .buttonish.w-secondary {
    width: calc(100% - 42px - 1px);
    vertical-align: middle;
  }
  body:not(.force-desktop) .buttonish.secondary-button {
    width: 42px;
    vertical-align: middle;
  }
}
.button-bottom-bar:not(:empty) {
  margin-top: 20px;
}
.button-bottom-bar.r-align > .buttonish {
  margin-left: 20px;
}
.button-bottom-bar.r-align > .action {
  margin-left: 20px;
}
.button-bottom-bar.r-align > .action.l-float {
  line-height: 40px;
  margin-left: 0;
  margin-right: 20px;
}
.button-bottom-bar.l-align > .buttonish {
  margin-right: 20px;
}
.button-bottom-bar.l-align > .action {
  margin-right: 20px;
}
.button-bottom-bar > .pipe {
  color: #92959d;
  line-height: 40px;
  margin-left: -10px;
  margin-right: 10px;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .button-bottom-bar.r-align, body:not(.force-desktop) .button-bottom-bar.l-align {
    text-align: center;
  }
  body:not(.force-desktop) .button-bottom-bar.r-align > .action.l-float, body:not(.force-desktop) .button-bottom-bar.r-align > .action.r-float, body:not(.force-desktop) .button-bottom-bar.l-align > .action.l-float, body:not(.force-desktop) .button-bottom-bar.l-align > .action.r-float {
    float: none;
    margin: 0;
    display: block;
  }
  body:not(.force-desktop) .button-bottom-bar.r-align > .buttonish, body:not(.force-desktop) .button-bottom-bar.l-align > .buttonish {
    margin: 15px 0 0 0;
  }
  body:not(.force-desktop) .button-bottom-bar.r-align > .buttonish:first-child, body:not(.force-desktop) .button-bottom-bar.l-align > .buttonish:first-child {
    margin: 0;
  }
  body:not(.force-desktop) .button-bottom-bar.error {
    text-align: center;
  }
}
.documents-filter-bar {
  margin-bottom: 40px;
}
.documents-filter-bar > h2 {
  margin-top: 2px;
}
.documents-filter-bar > .split-table > .control-row.full-line {
  margin-top: 0;
  width: calc(33% - 20px);
}
.documents-filter-bar > .active-description {
  margin-top: 20px;
  padding: 0 10px;
}
.documents-filter-bar > .active-description > .link {
  margin-left: 5px;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .documents-filter-bar {
    margin-bottom: 30px;
  }
  body:not(.force-desktop) .documents-filter-bar > .split-table > .control-row.full-line {
    width: auto;
  }
  body:not(.force-desktop) .documents-filter-bar > .split-table > .control-row.full-line:not(:first-child) {
    margin-top: 20px;
  }
  body:not(.force-desktop) .documents-filter-bar > .active-description {
    margin-top: 20px;
    padding: 0;
    text-align: center;
    line-height: 1.5;
  }
  body:not(.force-desktop) .documents-filter-bar > .active-description > img {
    display: block;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  body:not(.force-desktop) .documents-filter-bar > .active-description > .link {
    margin-left: 0;
    display: block;
  }
}
.inputish {
  border: 1px solid #d0d7e6;
  border-radius: 6px;
  padding: 11px;
  box-sizing: border-box;
  transition: border 100ms ease-out;
  background: white;
}
.inputish.validation-failed {
  border-color: #b53310;
  background: #ffe8e2;
}
.inputish:disabled {
  background: #f0f3f8;
}
.inputish:hover:not(:disabled):not(:focus) {
  border-color: hsl(0, 0%, 70%);
}

input[type=date].inputish {
  padding-top: 9px;
  padding-bottom: 9px;
  cursor: text;
}

textarea.inputish {
  width: 300px;
  height: 100px;
  min-height: 100px;
  white-space: pre-line;
  resize: vertical;
}

/* Chrome, Safari, Edge, Opera */
input.inputish::-webkit-outer-spin-button,
input.inputish::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.inputish[type=number] {
  -moz-appearance: textfield;
}

.invoice-number-prefix-text {
  width: 50px !important;
  margin-right: 10px;
}

.control-row {
  display: block;
  margin: 10px 0;
}
.control-row:first-child {
  margin-top: 0;
}
.control-row:last-child {
  margin-bottom: 0;
}
.control-row span.text {
  display: inline-block;
  width: 50%;
  text-align: right;
  box-sizing: border-box;
  padding: 0 20px;
  vertical-align: middle;
}
.control-row > div.input-holder {
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
}
.control-row > div.input-holder input, .control-row > div.input-holder textarea {
  width: 100%;
}
.control-row > div.input-holder > div.flex-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.control-row > div.input-holder > div.flex-wrapper > .control-info {
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}
.control-row > div.error {
  margin-top: 10px;
  padding-left: 50%;
  font-size: 12px;
  margin-bottom: 20px;
}
.control-row.full-line {
  margin-top: 20px;
  margin-bottom: 0;
}
.control-row.full-line.no-top-margin {
  margin-top: 0;
}
.control-row.full-line span.text {
  display: block;
  width: auto;
  text-align: left;
  padding: 0;
  margin-left: 10px;
  margin-bottom: 8px;
}
.control-row.full-line div.input-holder {
  width: 100%;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .control-row {
    margin-top: 20px;
    margin-bottom: 0;
  }
  body:not(.force-desktop) .control-row:first-child {
    margin-top: 0;
  }
  body:not(.force-desktop) .control-row.no-top-margin {
    margin-top: 0;
  }
  body:not(.force-desktop) .control-row span.text {
    font-size: 11px;
    color: #92959d;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    width: auto;
    text-align: left;
    padding: 0;
    margin-left: 10px;
    margin-bottom: 8px;
  }
  body:not(.force-desktop) .control-row div.input-holder {
    width: 100%;
  }
  body:not(.force-desktop) .control-row > .select-box-control {
    width: 100%;
  }
}
.check-box > span.text + .container {
  padding-top: 6px;
}
.check-box > .container {
  /* Hide the browser's default checkbox */
}
.check-box > .container > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.check-box > .container > .checkmark {
  padding: 3px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.check-box > .container > .checkmark > svg {
  visibility: hidden;
  position: relative;
  top: 1px;
}
.check-box > .container > input:checked ~ .checkmark > svg {
  visibility: visible;
}
.check-box > .container > input:focus ~ .checkmark {
  box-shadow: 0 0 0 1px #2684FF;
  border-color: #2684FF;
}
.check-box > .container > .label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
.check-box > .container > .label.with-description {
  font-size: 12px;
}
.check-box > .container > .label.with-description > .description {
  color: #92959d;
  font-size: 10px;
  margin-top: 4px;
  margin-left: 1px;
}
.check-box.disabled > .container > .checkmark {
  cursor: auto;
  border-color: #d0d7e6;
}
.check-box.disabled > .container > .label {
  color: #92959d;
}
.check-box.disabled > .container > .label.with-description > .description {
  color: #92959d;
}

.inline-checkbox > .container {
  display: flex;
  align-items: flex-start;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .check-box > span.text {
    display: none;
  }
  body:not(.force-desktop) .check-box > span.text + .container {
    padding: 0;
  }
}
.partner-text-box {
  margin-bottom: 40px;
}
.partner-text-box > .details {
  position: relative;
}
.partner-text-box > .details > ul > li:not(:empty) {
  padding: 3px 0;
}
.partner-text-box > .details > .floaty {
  top: -40px;
}
.partner-text-box > .details > .remove-button {
  right: -9px;
}
.partner-text-box > .details > .edit-button {
  right: 30px;
}
.partner-text-box > div.error {
  margin-top: 10px;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .partner-text-box {
    margin-bottom: 20px;
  }
}
.organization-option > .name {
  font-weight: 600;
}

.full-page-spinner {
  margin: 0px auto;
  width: 300px;
  padding-top: 60px;
  text-align: center;
  color: #4a4e55;
}

div.list {
  margin-top: -10px;
}
div.list > .row {
  display: flex;
  align-items: baseline;
}
div.list > .row.header > .col:first-child {
  padding-left: 16px;
}
div.list > .row.header > .col:last-child {
  padding-right: 16px;
}
div.list > .row.header > .col > span.text-list-item {
  display: inline-block;
  line-height: 40px;
}
div.list > .row.header > .col > span.text-list-item > svg {
  margin-left: 7px;
  position: relative;
  top: 2px;
}
div.list > .row.header > .col > span.text-list-item.clickable {
  cursor: pointer;
}
div.list > .row.header > .col > span.text-list-item.selected {
  color: #567595;
}
div.list > .row.header > .col > span.text-list-item.selected.direction0 > svg path:first-child {
  fill: #567595;
}
div.list > .row.header > .col > span.text-list-item.selected.direction1 > svg path:last-child {
  fill: #567595;
}
div.list > .row.data {
  color: #4a4e55;
  background: white;
  border-radius: 12px;
  padding: 9px 16px;
  height: 30px;
  line-height: 30px;
  outline: none;
  border: 1px solid transparent;
}
div.list > .row.data:not(:last-child) {
  margin-bottom: 8px;
}
div.list > .row.data:not(.readonly) {
  cursor: pointer;
}
div.list > .row .col {
  margin-right: 20px;
  box-sizing: border-box;
  flex: 0.4;
}
div.list > .row .col.main {
  flex: 0.8;
}
div.list > .row .col.short {
  flex: 0.2;
}
div.list > .row .col.visible-overflow {
  overflow: visible;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) div.list {
    margin-top: 0;
  }
  body:not(.force-desktop) div.list > .row.data {
    display: block;
    height: auto;
    line-height: normal;
  }
  body:not(.force-desktop) div.list > .row.data .col {
    margin-right: 0;
    font-weight: 600;
  }
  body:not(.force-desktop) div.list > .row.data .col > span.header {
    font-weight: normal;
    color: #92959d;
  }
}
.split-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.split-table > .control-row {
  width: calc(50% - 20px);
}
.split-table > .control-row.full {
  width: 100%;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .split-table {
    display: block;
  }
  body:not(.force-desktop) .split-table > .control-row {
    width: 100%;
  }
}
.menu-dropdown-wrap {
  position: relative;
  max-width: 100px;
}
.menu-dropdown-wrap > .mini-dropdown-box {
  padding-left: 10px;
  border-radius: 6px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #f9f9fb;
  display: flex;
  justify-content: space-between;
  color: #4a4e55;
  cursor: pointer;
}
.menu-dropdown-wrap > .mini-dropdown-box.readonly {
  padding-right: 10px;
  cursor: default;
}
.menu-dropdown-wrap > .mini-dropdown-box path {
  fill: #4a4e55;
}
.menu-dropdown-wrap > .mini-dropdown-box > div.dropdown-icon-placeholder {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 8px;
  padding-right: 10px;
}
.menu-dropdown-wrap > .mini-dropdown-box.red {
  background-color: #ffe8e2;
  color: #b53310;
}
.menu-dropdown-wrap > .mini-dropdown-box.red path {
  fill: #b53310;
}
.menu-dropdown-wrap > .mini-dropdown-box.green {
  background-color: #cdfbd4;
  color: #088a68;
}
.menu-dropdown-wrap > .mini-dropdown-box.green path {
  fill: #088a68;
}
.menu-dropdown-wrap > .dropdown-list.mini-dropdown-menu {
  font-size: 12px;
  right: 0;
  margin-top: 5px;
}

.dropdown-list {
  position: absolute;
  z-index: 1;
  background: white;
  min-width: 200px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border: 1px solid #e2e6f0;
  text-align: left;
  line-height: normal;
}
.dropdown-list > .dropdown-item {
  border: none;
  outline: none;
  background: none;
  padding: 10px;
  display: block;
  border-bottom: 1px solid #e2e6f0;
  color: #4a4e55;
  cursor: pointer;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  white-space: nowrap;
}
.dropdown-list > .dropdown-item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.dropdown-list > .dropdown-item:last-child {
  border-bottom: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.dropdown-list > .dropdown-item:hover {
  background-color: #f0f3f8;
}

.empty-view {
  margin-top: 80px;
  text-align: center;
  color: #4a4e55;
}
.empty-view > h2 {
  margin-top: 32px;
  margin-bottom: 40px;
}
.empty-view > .buttonish.create {
  margin: 0;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .empty-view {
    margin-top: 60px;
  }
  body:not(.force-desktop) .empty-view .buttonish {
    display: none;
  }
}
div.pagination {
  margin-top: 20px;
}
div.pagination > button {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 9px;
  padding: 0;
}
div.pagination > button.r-float {
  margin-right: 0;
  margin-left: 10px;
}
div.pagination > button > svg {
  margin-right: 0;
  position: relative;
  top: -1px;
  left: 0px;
  width: 12px;
  height: 10px;
}

.flip-h {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) div.pagination > button {
    width: 40px;
  }
}
.control-row > .react-datepicker-wrapper {
  width: 50%;
}
.control-row > .react-datepicker-wrapper .inputish {
  width: 100%;
}
.control-row.full-line > .react-datepicker-wrapper {
  width: 100%;
}

#date-picker-portal {
  position: fixed;
  z-index: 5000;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .control-row > .react-datepicker-wrapper {
    width: 100%;
  }
}
.invoice-stats-bar {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 0 -10px;
  padding-bottom: 30px;
}
.invoice-stats-bar > .box {
  background: white;
  border-radius: 12px;
  padding: 20px 16px;
  flex: 1;
  margin: 0 10px;
  display: flex;
}
.invoice-stats-bar > .box > svg {
  height: 50px;
}
.invoice-stats-bar > .box > div.texts {
  margin-left: 10px;
}
.invoice-stats-bar > .box > div.texts > div.title {
  font-weight: 600;
  color: #242526;
  text-transform: uppercase;
}
.invoice-stats-bar > .box > div.texts > div.title > div.year-changer {
  position: absolute;
  display: inline;
  padding: 5px;
  margin-top: -5px;
  cursor: pointer;
}
.invoice-stats-bar > .box > div.texts > button.sum {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  font-size: 28px;
  color: #242526;
  font-weight: 300;
  display: inline;
}
.invoice-stats-bar > .box > div.texts > button.sum.loading {
  color: #d0d7e6;
}
.invoice-stats-bar > .box > div.texts > button.sum:not(:disabled) {
  cursor: pointer;
}

.signature-file-upload {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
}
.signature-file-upload > .button {
  padding: 11px;
  cursor: pointer;
}
.signature-file-upload > .button > svg {
  width: 20px;
  height: 20px;
  fill: #808ba1;
}
.signature-file-upload > .button:hover > svg {
  fill: #5d6a8a;
}

input[type=file] {
  display: none;
}

.signature-file-preview {
  padding: 0;
  width: 100%;
  height: 100px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signature-file-preview > img {
  max-height: 80px;
}

.floaty.signature-file-deselect {
  top: 5px;
  right: 5px;
  width: 40px;
}

.signature-file-size-error {
  margin: 10px;
}

ol.tab-control {
  display: inline-flex;
  border-radius: 8px;
  background: #e2e6f0;
  padding: 2px;
  margin-bottom: 40px;
}
ol.tab-control > li {
  text-align: center;
  padding: 8px 40px;
  color: #4a4e55;
}
ol.tab-control > li:not(.selected) {
  cursor: pointer;
}
ol.tab-control > li:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
ol.tab-control > li:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
ol.tab-control > li.selected {
  background: #f9f9fb;
  color: #242526;
  border-bottom-color: #11c16f;
  border-radius: 8px;
}

.tab-center {
  text-align: center;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) ol.tab-control {
    margin-bottom: 20px;
  }
}
.ribbon-holder {
  position: relative;
}

.ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before, .ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #3498db;
}
.ribbon.green::before, .ribbon.green::after {
  border-color: #11c16f;
}
.ribbon > div {
  position: absolute;
  display: block;
  width: 250px;
  padding: 10px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}
.ribbon > div > div.text {
  margin-left: -22px;
}
.ribbon.green > div {
  background-color: #11c16f;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left > div {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right > div {
  left: -56px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}

.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}

.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}

.ribbon-bottom-left > div {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}

.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}

.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}

.ribbon-bottom-right > div {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}

.subscription-info {
  padding: 40px;
  text-align: center;
  border-bottom: 1px solid #d0d7e6;
}
.subscription-info > .gray-uppercase, .page.invoice .subscription-info > .line-label, .control-row .subscription-info > span.text, div.list.subscription-info > .row.header {
  display: inline-block;
}
.subscription-info > .gray-uppercase > .edit-link, .page.invoice .subscription-info > .line-label > .edit-link, .control-row .subscription-info > span.text > .edit-link, div.list.subscription-info > .row.header > .edit-link {
  margin-left: 5px;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .subscription-info > img {
    top: 0;
    margin-bottom: 5px;
  }
  body:not(.force-desktop) .subscription-info > .gray-uppercase, body:not(.force-desktop) .page.invoice .subscription-info > .line-label, .page.invoice body:not(.force-desktop) .subscription-info > .line-label, body:not(.force-desktop) .control-row .subscription-info > span.text, .control-row body:not(.force-desktop) .subscription-info > span.text, body:not(.force-desktop) div.list.subscription-info > .row.header {
    display: block;
  }
  body:not(.force-desktop) .subscription-info > .gray-uppercase > .edit-link, body:not(.force-desktop) .page.invoice .subscription-info > .line-label > .edit-link, .page.invoice body:not(.force-desktop) .subscription-info > .line-label > .edit-link, body:not(.force-desktop) .control-row .subscription-info > span.text > .edit-link, .control-row body:not(.force-desktop) .subscription-info > span.text > .edit-link, body:not(.force-desktop) div.list.subscription-info > .row.header > .edit-link {
    margin: 5px 0 0 0;
    display: block;
  }
}
.hamburger {
  position: relative;
  width: 100%;
  height: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.hamburger > span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #242526;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.hamburger > span:nth-child(1) {
  top: calc(16.5% - 1px);
}
.hamburger > span:nth-child(2), .hamburger > span:nth-child(3) {
  top: calc(49.5% - 1px);
}
.hamburger > span:nth-child(4) {
  top: calc(82.5% - 1px);
}
.hamburger.open > span:nth-child(1) {
  top: calc(49.5% - 1px);
  width: 0%;
  left: 50%;
}
.hamburger.open > span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.hamburger.open > span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.hamburger.open > span:nth-child(4) {
  top: calc(49.5% - 1px);
  width: 0%;
  left: 50%;
}

.payment-history {
  position: relative;
  gap: 20px;
}
.payment-history > .remove-button {
  left: -32px;
  top: 40px;
}
.payment-history > .control-row {
  margin: 0;
}
.payment-history > .control-row.small {
  width: 180px;
}
.payment-history > .control-row.big {
  width: auto;
  flex-grow: 1;
}
.payment-history:not(.index0) {
  padding-top: 10px;
}
.payment-history:not(.index0) > .control-row > .text {
  display: none;
}
.payment-history:not(.index0) > .remove-button {
  top: 10px;
}

.payment-progress > .progress-bar {
  width: 100%;
  height: 5px;
  background: #92959d;
  position: relative;
  border-radius: 5px;
  margin-top: 10px;
}
.payment-progress > .progress-bar > .fill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #11c16f;
  border-radius: 5px;
  transition: width 0.2s;
}
.payment-progress > .text {
  margin-top: 5px;
}
.payment-progress > .text > span.header {
  margin-right: 4px;
}
.payment-progress > .text > span.value {
  font-weight: 600;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .payment-history > .remove-button {
    top: 25px;
    left: auto;
    right: -9px;
  }
  body:not(.force-desktop) .payment-history:not(.index0) {
    border-top: 1px dashed #d0d7e6;
    margin-top: 20px;
    padding-top: 0;
  }
  body:not(.force-desktop) .payment-history:not(.index0) > .control-row > .text {
    display: block;
  }
  body:not(.force-desktop) .payment-history:not(.index0) > .remove-button {
    top: 5px;
  }
}
.black-header-table {
  text-align: left;
  border-collapse: collapse;
  outline: 1px solid #e2e6f0;
  border-radius: 6px;
  overflow: hidden;
}
.black-header-table > thead > tr {
  background: #242526;
  color: #f9f9fb;
}
.black-header-table > thead > tr > th {
  padding: 12px 10px;
  font-weight: normal;
}
.black-header-table > thead > tr > th.w-padding {
  padding-left: 20px;
}
.black-header-table > tbody > tr {
  border-bottom: 1px solid #e2e6f0;
}
.black-header-table > tbody > tr:nth-child(odd) {
  background: #f9f9fb;
}
.black-header-table > tbody > tr:nth-child(even) {
  background: #f0f3f8;
}
.black-header-table > tbody > tr:last-child {
  border-bottom: none;
}
.black-header-table > tbody > tr > td {
  padding: 12px 10px;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) table.black-header-table > thead {
    display: none;
  }
  body:not(.force-desktop) table.black-header-table td {
    display: flex;
  }
  body:not(.force-desktop) table.black-header-table td.w-badge {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  body:not(.force-desktop) table.black-header-table td::before {
    content: attr(data-label);
    font-weight: 600;
    width: 120px;
    min-width: 120px;
  }
}
.page.authentication {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  box-sizing: border-box;
}
.page.authentication > .logo {
  text-align: center;
}
.page.authentication > .logo > a {
  padding: 10px;
  display: inline-block;
}
.page.authentication > .logo > a > svg {
  fill: #242526;
  width: 50px;
  height: 50px;
}
.page.authentication > h1 {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  line-height: 1.5;
}
.page.authentication .bottom-bar {
  text-align: center;
  margin: 40px 0;
  line-height: 1.5;
}
.page.authentication .bottom-bar > div.line {
  margin: 20px 0;
}
.page.authentication .legal-footer {
  margin-top: 120px;
  padding-top: 20px;
  border-top: 1px solid #d0d7e6;
  color: #d0d7e6;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .page.authentication > h1 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  body:not(.force-desktop) .page.authentication .bottom-bar {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  body:not(.force-desktop) .page.authentication .bottom-bar > div.line {
    margin: 0;
    margin-top: 10px;
  }
  body:not(.force-desktop) .page.authentication .legal-footer {
    margin-top: 0;
  }
}
div.error-view {
  margin-top: 100px;
  text-align: center;
}
div.error-view > div.message {
  margin-top: 60px;
  line-height: 2;
}
div.error-view > div.link {
  margin-top: 40px;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) div.error-view {
    margin: 20px;
  }
  body:not(.force-desktop) div.error-view > img {
    width: 80px;
  }
  body:not(.force-desktop) div.error-view > div.message {
    margin-top: 20px;
    line-height: 1.5;
  }
  body:not(.force-desktop) div.error-view > div.link {
    margin-top: 20px;
  }
}
.invoice-item-list > .invoice-item {
  display: flex;
}
.invoice-item-list > .invoice-item.row {
  margin-bottom: 10px;
  position: relative;
}
.invoice-item-list > .invoice-item > .remove-button {
  top: 0;
  left: -36px;
}
.invoice-item-list > .invoice-item > .col {
  padding-right: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: baseline;
}
.invoice-item-list > .invoice-item > .col:last-child {
  padding-right: 0;
}
.invoice-item-list > .invoice-item > .col > .inputable {
  width: 100%;
}
.invoice-item-list > .invoice-item > .col > .inputable:not(.touched) {
  color: #92959d;
}
.invoice-item-list > .invoice-item > .col > textarea {
  height: 40px;
  min-height: 40px;
}
.invoice-item-list > .invoice-item > .col.fluid {
  flex: 1;
}
.invoice-item-list > .invoice-item > .col.fixed {
  width: 100px;
}
.invoice-item-list > .invoice-item > .col.fixed.w-appendix {
  width: 140px;
}
.invoice-item-list > .invoice-item > .col.fixed.w-appendix > .appendix-unit {
  display: inline-block;
  box-sizing: border-box;
  padding-left: 8px;
}
.invoice-item-list > .invoice-item > .col.fixed.w-appendix.short {
  width: 90px;
}
.invoice-item-list > .invoice-item > .col.fixed.shortest {
  width: 80px;
}
.invoice-item-list > ul.summary {
  width: 320px;
  box-sizing: border-box;
  text-align: right;
}
.invoice-item-list > ul.summary.disabled {
  color: #808ba1;
}
.invoice-item-list > ul.summary > li {
  padding-bottom: 10px;
}
.invoice-item-list > ul.summary > li > span.value {
  font-weight: 600;
  margin-left: 5px;
}
.invoice-item-list > ul.summary > li:last-child {
  padding-top: 10px;
  border-top: 1px dashed #d0d7e6;
}
.invoice-item-list > .tax-description {
  margin: 0;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .invoice-item-list > .invoice-item {
    display: block;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item.header {
    display: none;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item.row {
    border-top: 1px dashed #d0d7e6;
    padding-top: 15px;
    margin-bottom: 0;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .col {
    padding-bottom: 10px;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .col.show-only-on-mobile {
    padding-bottom: 0;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .col.fluid {
    padding-right: 0;
    width: 100%;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .col.fixed {
    float: left;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .col.fixed.w-appendix.groupable {
    position: relative;
    width: calc(100% - 160px);
    padding-right: 0;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .col.fixed.w-appendix.groupable.short {
    padding-left: 10px;
    width: 80px;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .col.fixed.w-appendix.groupable > .appendix-unit {
    font-size: 10px;
    position: absolute;
    right: 5px;
    top: 15px;
    pointer-events: none;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .col.hide-on-mobile {
    display: none;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .remove-button {
    top: 0;
    left: auto;
    right: -9px;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .line-label {
    clear: both;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .space-between {
    clear: both;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .space-between > .line-label {
    display: inline-block;
    width: calc(100% - 160px);
    margin-left: 0;
    padding-left: 10px;
    box-sizing: border-box;
  }
  body:not(.force-desktop) .invoice-item-list > .invoice-item > .space-between > .line-label.short {
    width: 80px;
    padding-left: 20px;
  }
  body:not(.force-desktop) .invoice-item-list > ul.summary {
    width: 100%;
  }
  body:not(.force-desktop) .invoice-item-list > .buttons {
    border-top: 1px dashed #d0d7e6;
    margin: 0;
    margin-top: 10px;
    padding: 20px 0;
  }
  body:not(.force-desktop) .invoice-item-list > .tax-description {
    margin: 20px 0;
  }
}
.page.invoice {
  padding-top: 40px;
  padding-bottom: 40px;
}
.page.invoice .control-row > div.input-holder > div.flex-wrapper > .control-info.edit-invoice-numbering-info {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0;
  line-height: 45px;
}
.page.invoice .control-row > div.input-holder > div.flex-wrapper > .control-info.edit-invoice-numbering-info > svg {
  width: 16px;
  height: 16px;
}
.page.invoice .control-row > div.input-holder > div.flex-wrapper > .control-info.edit-invoice-numbering-info > svg path {
  fill: #92959d;
}
.page.invoice .control-row > div.input-holder > div.flex-wrapper > .control-info.edit-invoice-numbering-info:hover > svg path {
  fill: #5d6a8a;
}
.page.invoice .line-label {
  margin-left: 10px;
  margin-bottom: 12px;
}
.page.invoice .line-label.no-margin {
  margin-left: 0;
}
.page.invoice > div.invoice-center {
  margin-bottom: 40px;
}
.page.invoice > div.invoice-metadata {
  width: 360px;
}
.page.invoice > div.column.invoice-partner {
  width: 400px;
}
.page.invoice > div.column.invoice-signature {
  width: 320px;
}
.page.invoice > div.column.invoice-signature > .control-row.full-line {
  margin-top: 0;
}
.page.invoice > div.column.invoice-attribution {
  width: 400px;
}
.page.invoice > div.column.invoice-attribution > textarea {
  width: 100%;
}
.page.invoice > .button-bottom-bar > .secondary.text-only {
  padding-left: 20px;
  padding-right: 20px;
}

#invisible-print-container {
  display: none;
}

.email-verification-required-text {
  line-height: 1.5;
  margin-top: 40px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .page.invoice {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  body:not(.force-desktop) .page.invoice textarea {
    height: 120px;
    min-height: 120px;
  }
  body:not(.force-desktop) .page.invoice .line-label {
    margin-bottom: 8px;
  }
  body:not(.force-desktop) .page.invoice .line-label.no-margin {
    margin-left: 10px;
  }
  body:not(.force-desktop) .page.invoice > div.invoice-metadata {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
  body:not(.force-desktop) .page.invoice > div.invoice-center {
    margin-bottom: 20px;
  }
  body:not(.force-desktop) .page.invoice > div.invoice-center > .invoice-item-list-header {
    margin-top: 0;
    margin-bottom: 20px;
  }
  body:not(.force-desktop) .page.invoice > div.column.invoice-partner {
    width: 100%;
  }
  body:not(.force-desktop) .page.invoice > div.column.invoice-attribution {
    width: 100%;
    float: none;
  }
  body:not(.force-desktop) .page.invoice > div.column.invoice-signature {
    width: 100%;
    float: none;
    margin-top: 20px;
  }
  body:not(.force-desktop) .email-verification-required-text {
    text-align: center;
    margin: 0;
  }
}
.package-types-tabs {
  padding-left: 340px;
  text-align: center;
  margin-bottom: 40px;
  box-sizing: border-box;
  min-width: 960px;
}

.pricing-flex {
  min-width: 960px;
  justify-content: center;
}
.pricing-flex > .column > .box {
  background: white;
  border-radius: 6px;
  text-align: center;
  margin: 0 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 200px;
}
.pricing-flex > .column > .box.most-popular {
  width: 240px;
  margin-top: -20px;
  margin-bottom: -20px;
}
.pricing-flex > .column > .box.most-popular > .column-header {
  padding-top: 40px;
}
.pricing-flex > .column > .box > .row:nth-child(even) {
  background: #f9f9fb;
}
.pricing-flex > .column .column-header {
  height: 160px;
  padding: 20px;
}
.pricing-flex > .column .column-header > .description {
  line-height: 20px;
  font-size: 12px;
}
.pricing-flex > .column .column-footer {
  padding: 40px 0;
}
.pricing-flex > .column .column-footer > div.selected {
  line-height: 40px;
}
.pricing-flex > .column .column-footer > div.selected > button {
  margin-top: 10px;
}
.pricing-flex > .column .row {
  height: 30px;
  line-height: 30px;
}
.pricing-flex > .column .row > .emoji {
  width: 20px;
}
.pricing-flex > .column.row-header {
  font-size: 12px;
  min-width: 340px;
  text-align: right;
}
.pricing-flex > .column.row-header > div.row {
  padding-right: 20px;
}
.pricing-flex > .column .buttonish {
  padding-left: 20px;
  padding-right: 20px;
}

.pricing-footer {
  margin-left: 300px;
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 12px;
  line-height: 1.5;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .package-types-tabs {
    min-width: 0;
    padding-left: 0;
    margin-top: 20px;
    margin-bottom: 0;
  }
  body:not(.force-desktop) .pricing-flex {
    display: block;
    min-width: 0;
  }
  body:not(.force-desktop) .pricing-flex > .column.inline-flex {
    display: block;
  }
  body:not(.force-desktop) .pricing-flex > .column.inline-flex > .box {
    width: auto;
    margin: 0;
    margin-bottom: 20px;
  }
  body:not(.force-desktop) .pricing-flex > .column.inline-flex .row {
    display: none;
  }
  body:not(.force-desktop) .pricing-flex > .column.inline-flex .buttonish {
    width: auto;
  }
  body:not(.force-desktop) .pricing-flex > .column.inline-flex .column-header {
    padding: 20px 0;
    height: auto;
  }
  body:not(.force-desktop) .pricing-flex > .column.inline-flex .column-footer {
    padding: 0;
    padding-bottom: 20px;
  }
  body:not(.force-desktop) .pricing-footer {
    margin: 20px 0;
  }
  body:not(.force-desktop) .pricing-footer > div {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .page.organization .control-row {
    margin-top: 20px !important;
  }
}
body {
  background: #f0f3f8;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.inline-icon, .invoice-attachment > svg, .buttonish > svg {
  display: inline-block;
  vertical-align: middle;
}

.page {
  position: relative;
  margin-left: 60px;
  padding: 0 40px;
  min-width: 820px;
}
.page > h1 {
  margin-top: 0;
}

.content {
  max-width: 320px;
  margin: 0 auto;
  padding-top: 20px;
}

.gray-uppercase, .control-row span.text, .control-row.full-line span.text, div.list > .row.header, .page.invoice .line-label {
  font-size: 11px;
  color: #92959d;
  text-transform: uppercase;
  font-weight: 600;
}

.panel {
  margin-bottom: 40px;
}

.dummy-top {
  height: 34px;
}

.ellipsiswrap, .upload-attachment, .invoice-attachment, div.list > .row .col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bluish:focus, .buttonish:focus, .inputish:focus, div.list > .row.data:focus:not(.readonly) {
  outline: none;
  box-shadow: 0 0 0 1px #2684FF;
  border-color: #2684FF;
}

.clear-float {
  clear: both;
}

.clear-l-float {
  clear: left;
}

.clear-r-float {
  clear: right;
}

.l-float {
  float: left;
}

.r-float {
  float: right;
}

.r-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.hidden {
  display: none;
}

.locked {
  pointer-events: none;
}

.floaty {
  position: absolute;
  width: 32px;
  height: 40px;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  color: #4a4e55;
  border-radius: 6px;
}
.floaty > svg {
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
}
.floaty > svg path {
  fill: #4a4e55;
}
.floaty:hover {
  background: #d0d7e6;
}

.invoice-attachment-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.invoice-attachment {
  border-radius: 6px;
  padding: 0 15px;
  font-size: 12px;
  line-height: 40px;
  background: #e2e6f0;
  color: #4a4e55;
  cursor: pointer;
  max-width: 200px;
}
.invoice-attachment > svg {
  margin-right: 11px;
  position: relative;
  top: -1px;
}
.invoice-attachment > svg path {
  fill: #4a4e55;
}
.invoice-attachment.error {
  background: #ffe8e2;
  color: #b53310;
  cursor: default;
}
.invoice-attachment.error:hover {
  color: #b53310;
}
.invoice-attachment.error > svg path {
  fill: #b53310;
}
.invoice-attachment.error > button.remove {
  color: #b53310;
}
.invoice-attachment.with-remove-button {
  position: relative;
  padding-right: 40px;
}
.invoice-attachment.with-remove-button > button.remove {
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  text-align: center;
  background: none;
  cursor: pointer;
}
.invoice-attachment.uploading {
  cursor: default;
  position: relative;
}
.invoice-attachment.uploading > .upload-indicator {
  opacity: 1;
}
.invoice-attachment:hover {
  color: #4a4e55;
}
.invoice-attachment > .upload-indicator {
  opacity: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  background: #11c16f;
  transition: width 0.1s ease-in-out, opacity 0.2s ease-in-out;
}

.upload-attachment {
  line-height: 40px;
  margin-left: 10px;
  display: inline-block;
}

.bold {
  font-weight: 600;
}

.fav-star {
  margin-right: 5px;
  display: inline;
  vertical-align: baseline;
}

.noselect, .buttonish {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Edge, Opera and Firefox */
}

.emoji {
  display: inline-block;
  vertical-align: baseline;
  width: 23px;
  margin: 0 6px;
  position: relative;
  top: 2px;
}
.emoji.top3 {
  top: 3px;
}
.emoji.top4 {
  top: 4px;
}
.emoji.top5 {
  top: 5px;
}
.emoji.top6 {
  top: 6px;
}
.emoji.top7 {
  top: 7px;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.inline {
  display: inline;
}

.show-only-on-mobile {
  display: none;
}

.transparent {
  opacity: 0;
}

.disclaimer-text {
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
}
.disclaimer-text > span {
  margin-top: 5px;
  line-height: 1.5;
}

.text-box-appendix {
  padding-left: 8px;
}

.modal-error-message {
  padding: 10px 0;
}

.after-table-buttons {
  margin: 10px 0;
}

.badge {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 20px;
  border: 1px solid #e2e6f0;
  background-color: #f9f9fb;
}
.badge.red {
  background-color: #ffe8e2;
  color: #b53310;
  border-color: #b53310;
}
.badge.green {
  background-color: #cdfbd4;
  color: #088a68;
  border-color: #11c16f;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .hide-on-mobile {
    display: none;
  }
  body:not(.force-desktop) .show-only-on-mobile {
    display: block;
  }
  body:not(.force-desktop) .show-only-on-mobile.inline {
    display: inline;
  }
  body:not(.force-desktop) .content {
    max-width: none;
    padding-top: 0;
  }
  body:not(.force-desktop) .panel {
    margin-bottom: 20px;
  }
  body:not(.force-desktop) .page {
    margin-left: 0;
    padding: 0 20px;
    position: static;
    min-width: 0;
  }
  body:not(.force-desktop) .buttonish.create.square.filter-button {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 0;
    width: auto;
    height: auto;
    padding: 12px 32px;
  }
  body:not(.force-desktop) .invoice-attachment {
    max-width: none;
    width: 100%;
  }
}