.control-row {
  > .react-datepicker-wrapper {
    width: 50%;
  
    .inputish {
      width: 100%;
    }
  }

  &.full-line {
    > .react-datepicker-wrapper {
      width: 100%;
    }
  }
}

#date-picker-portal {
  position: fixed;
  z-index: 5000;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .control-row > .react-datepicker-wrapper {
      width: 100%;
    }
  }
}
