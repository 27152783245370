$blue-ribbon-color: #3498db;
$green-ribbon-color: $ulverbite-green;

.ribbon-holder {
  position: relative;
}

.ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid $blue-ribbon-color;
  }

  &.green::before,
  &.green::after {
    border-color: $green-ribbon-color;
  }

  > div {
    position: absolute;
    display: block;
    width: 250px;
    padding: 10px 0;
    background-color: $blue-ribbon-color;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font-size: $font-size-small;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;

    > div.text {
      margin-left: -22px;
    }
  }
  
  &.green > div {
    background-color: $green-ribbon-color;
  }
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left > div {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right > div {
  left: -56px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left > div {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right > div {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}
