.invoice-item-list {
  > .invoice-item {
    display: flex;

    &.row {
      margin-bottom: 10px;
      position: relative;
    }

    > .remove-button {
      top: 0;
      left: -36px;
    }
    
    > .col {      
      padding-right: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: baseline;

      &:last-child {
        padding-right: 0;
      }
      
      > .inputable {
        width: 100%;

        &:not(.touched) {
          color: $ulverbite-pastel-gray;
        }
      }

      > textarea {
        height: 40px;
        min-height: 40px;
      }
    }
  
    > .col.fluid {
      flex: 1;
    }
  
    > .col.fixed {
      width: 100px;

      &.w-appendix {
        width: 140px;

        > .appendix-unit {
          display: inline-block;
          box-sizing: border-box;
          padding-left: 8px;
        }
      }

      &.w-appendix.short {
        width: 90px;
      }

      &.shortest {
        width: 80px;
      }
    }
  }

  > ul.summary {
    width: 320px;
    box-sizing: border-box;
    text-align: right;

    &.disabled {
      color: $ulverbite-another-gray;
    }

    > li {
      padding-bottom: 10px;

      > span.value {
        font-weight: 600;
        margin-left: 5px;
      }

      &:last-child {
        padding-top: 10px;
        border-top: 1px dashed $ulverbite-dark-gray;
      }
    }
  }

  > .tax-description {
    margin: 0;
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .invoice-item-list {
      > .invoice-item {
        display: block;

        &.header {
          display: none;
        }

        &.row {
          border-top: 1px dashed $ulverbite-dark-gray;
          padding-top: 15px;
          margin-bottom: 0;
        }

        > .col {
          padding-bottom: 10px;

          &.show-only-on-mobile {
            padding-bottom: 0;
          }

          &.fluid {
            padding-right: 0;
            width: 100%;
          }
          
          &.fixed {
            float: left;

            &.w-appendix.groupable {
              position: relative;
              width: calc(100% - 160px);
              padding-right: 0;

              &.short {
                padding-left: 10px;
                width: 80px;
              }

              > .appendix-unit {
                font-size: 10px;
                position: absolute;
                right: 5px;
                top: 15px;
                pointer-events: none;
              }
            }
          }

          &.hide-on-mobile {
            display: none;
          }
        }

        > .remove-button {
          top: 0;
          left: auto;
          right: -9px;
        }
        
        > .line-label {
          clear: both;
        }

        > .space-between {
          clear: both;

          > .line-label {
            display: inline-block;
            width: calc(100% - 160px);
            margin-left: 0;
            padding-left: 10px;
            box-sizing: border-box;
            
            &.short {
              width: 80px;
              padding-left: 20px;
            }
          }
        }
      }

      > ul.summary {
        width: 100%;
      }

      > .buttons {
        border-top: 1px dashed $ulverbite-dark-gray;
        margin: 0;
        margin-top: 10px;
        padding: 20px 0;
      }
      
      > .tax-description {
        margin: 20px 0;
      }
    }
  }
}
