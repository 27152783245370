.modal-content .MuiDialog-paper {
  background-color: $ulverbite-lightest-gray;
  border-radius: 12px;
  width: 800px;
  max-width: 800px;
  padding: 25px 40px;
  outline: none;
  box-sizing: border-box;
  position: relative;

  h1.title {
    text-align: center;
    margin: 0 40px 20px 40px;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 6px;

    > svg {
      margin-top: 20px;
      margin-left: 20px;
    }
  }

  .tab-center > ol.tab-control {
    margin-bottom: 20px;
  }
}

.alert-modal-content {
  text-align: center;
  padding: 10px 0;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .modal-content .MuiDialog-paper {
      padding: 20px 10px;
      margin-left: 5px;
      margin-right: 5px;
    }
    
    .alert-modal-content {
      padding: 0px;
    }
  }
}
