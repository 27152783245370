.page.authentication {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  box-sizing: border-box;

  > .logo {
    text-align: center;

    > a {
      padding: 10px;
      display: inline-block;

      > svg {
        fill: $ulverbite-black;
        width: 50px;
        height: 50px;
      }
    }
  }

  > h1 {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    line-height: 1.5;
  }

  .bottom-bar {
    text-align: center;
    margin: 40px 0;
    line-height: 1.5;

    > div.line {
      margin: 20px 0;
    }
  }
  
  .legal-footer {
    margin-top: 120px;
    padding-top: 20px;
    border-top: 1px solid $ulverbite-dark-gray;
    color: $ulverbite-dark-gray;
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .page.authentication {
      > h1 {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      
      .bottom-bar {
        margin-top: 20px;
        margin-bottom: 20px;

        > div.line {
          margin: 0;
          margin-top: 10px;
        }
      }

      .legal-footer {
        margin-top: 0;
      }
    }
  }
}
