.dropdown-list {
  position: absolute;
  z-index: 1;
  background :white;
  min-width: 200px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border: 1px solid $ulverbite-light-gray;
  text-align: left;
  line-height: normal;
  
  > .dropdown-item {
    border: none;
    outline: none;
    background: none;
    padding: 10px;
    display: block;
    border-bottom: 1px solid $ulverbite-light-gray;
    color: $ulverbite-light-black;
    cursor: pointer;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    white-space: nowrap;

    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:hover {
      background-color: $ulverbite-lightest-gray;
    }
  }
}
