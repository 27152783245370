@import "invoice-item-list.scss";

.page.invoice {
  padding-top: 40px;
  padding-bottom: 40px;

  .control-row > div.input-holder > div.flex-wrapper > .control-info.edit-invoice-numbering-info {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0;
    line-height: 45px;

    > svg {
      width: 16px;
      height: 16px;
  
      path {
        fill: $ulverbite-pastel-gray;
      }
    }

    &:hover > svg path {
      fill: $ulverbite-blue-gray;
    }
  }

  .line-label {
    @extend .gray-uppercase;
    margin-left: 10px;
    margin-bottom: 12px;

    &.no-margin {
      margin-left: 0;
    }
  }

  > div.invoice-center {
    margin-bottom: 40px;
  }

  > div.invoice-metadata {
    width: 360px;
  }

  > div.column.invoice-partner {
    width: 400px;
  }

  > div.column.invoice-signature {
    width: 320px;

    > .control-row.full-line {
      margin-top: 0;
    }
  }
  
  > div.column.invoice-attribution {
    width: 400px;
  
    > textarea {
      width: 100%;
    }
  }

  > .button-bottom-bar > .secondary {
    &.text-only {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

#invisible-print-container {
  display: none;
}

.email-verification-required-text {
  line-height: 1.5;
  margin-top: 40px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .page.invoice {
      padding-top: 20px;
      padding-bottom: 20px;

      textarea {
        height: 120px;
        min-height: 120px;
      }

      .line-label {
        margin-bottom: 8px;

        &.no-margin {
          margin-left: 10px;
        }
      }
      
      > div.invoice-metadata {
        width: 100%;
        float: none;
        margin-bottom: 20px;
      }

      > div.invoice-center {
        margin-bottom: 20px;

        > .invoice-item-list-header {
          margin-top: 0;
          margin-bottom: 20px;
        }
      }

      > div.column.invoice-partner {
        width: 100%;
      }
      
      > div.column.invoice-attribution {
        width: 100%;
        float: none;
      }
      
      > div.column.invoice-signature {
        width: 100%;
        float: none;
        margin-top: 20px;
      }
    }
    
    .email-verification-required-text {
      text-align: center;
      margin: 0;
    }
  }
}
