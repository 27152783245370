.inputish {
  @extend .bluish;
  border: 1px solid $ulverbite-dark-gray;
  border-radius: 6px;
  padding: 11px;
  box-sizing: border-box;
  transition : border 100ms ease-out;
  background: white;
  
  &.validation-failed {
    border-color: $ulverbite-red;
    background: $ulverbite-light-red;
  }

  &:disabled {
    background: $ulverbite-lightest-gray;
  }

  &:hover:not(:disabled):not(:focus) {
    border-color: hsl(0,0%,70%);
  }
}

input[type=date].inputish {
  padding-top: 9px;
  padding-bottom: 9px;
  cursor: text;
}

textarea.inputish {
  width: 300px;
  height: 100px;
  min-height: 100px;
  white-space: pre-line;
  resize: vertical;
}

// Hide arrows in number text boxes

/* Chrome, Safari, Edge, Opera */
input.inputish::-webkit-outer-spin-button,
input.inputish::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.inputish[type=number] {
  -moz-appearance:textfield;
}

.invoice-number-prefix-text {
  width: 50px !important;
  margin-right: 10px;
}
