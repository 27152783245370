// Blue
$queen-blue: #46688B;
$ucla-blue: #567595;

$ulverbite-white: #f9f9fb;
$ulverbite-lightest-gray: #f0f3f8;
$ulverbite-light-gray: #e2e6f0;
$ulverbite-dark-gray: #d0d7e6;
$ulverbite-pastel-gray: #92959d;
$ulverbite-another-gray: #808ba1;
$ulverbite-blue-gray: #5d6a8a;
$ulverbite-black: #242526;
$ulverbite-light-black: #4a4e55;
$ulverbite-green: #11c16f;
$ulverbite-dark-green: #0ca56d;
$ulverbite-darkest-green: #088a68;
$ulverbite-light-green: #cdfbd4;
$ulverbite-red: #b53310;
$ulverbite-light-red: #ffe8e2;

$yellow: #f6cc57;

.error {
  color: $ulverbite-red;
}
