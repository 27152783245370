.black-header-table {
  text-align: left;
  border-collapse: collapse;
  outline: 1px solid $ulverbite-light-gray;
  border-radius: 6px;
  overflow: hidden;
  
  > thead {
    > tr {
      background: $ulverbite-black;
      color: $ulverbite-white;

      > th {
        padding: 12px 10px;
        font-weight: normal;

        &.w-padding {
          padding-left: 20px;
        }
      }
    }
  }

  > tbody {
    > tr {
      border-bottom: 1px solid $ulverbite-light-gray;

      &:nth-child(odd) {
        background: $ulverbite-white;
      }
  
      &:nth-child(even) {
        background: $ulverbite-lightest-gray;
      }
 
      &:last-child {

        border-bottom: none;
      }

      > td {
        padding: 12px 10px;
      }
    }
  }

}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    table.black-header-table {
      > thead {
        display: none;
      }

      td {
        display: flex;

        &.w-badge {
          padding-top: 10px;
          padding-bottom: 0px;
        }
      }
      
      td::before {
        content: attr(data-label);
        font-weight: 600;
        width: 120px;
        min-width: 120px;
      }
    }
  }
}
